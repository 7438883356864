<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header data-cy="expand">
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="name"
                    v-model="searchParam.name"
                    data-cy="name"
                    label="Name"
                    type="text"
                    @keydown.enter="searchAllCarrier()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <carrier-sales-rep-auto-complete
                    data-cy="carrier-manager"
                    @event="selectedCarrierManager"
                    :default-carrier-sales-rep="searchParam.carrierManager"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="city"
                    v-model="searchParam.city"
                    data-cy="city"
                    label="City"
                    type="text"
                    @keydown.enter="searchAllCarrier()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="state"
                    v-model="searchParam.state"
                    data-cy="state"
                    hint="Short state name (Ex. Texas - TX)"
                    label="State"
                    persistent-hint
                    type="text"
                    @keydown.enter="searchAllCarrier()"
                  />
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field
                    ref="mc-number"
                    v-model="searchParam.mcNumber"
                    data-cy="mc-number"
                    label="MC Number"
                    type="text"
                    @keydown.enter="searchAllCarrier()"
                  />
                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-text-field
                    ref="dot-number"
                    v-model="searchParam.dotNumber"
                    data-cy="dot-number"
                    label="DOT Number"
                    type="text"
                    @keydown.enter="searchAllCarrier()"
                  />
                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-text-field
                    ref="scac"
                    v-model="searchParam.scac"
                    data-cy="scac"
                    label="SCAC"
                    type="text"
                    @keydown.enter="searchAllCarrier()"
                  />
                </v-col>
                <v-col cols="12" md="2" sm="6">
                  <v-select
                    ref="approved"
                    v-model="searchParam.approved"
                    :items="carrierApproved"
                    data-cy="approved"
                    item-text="name"
                    item-value="id"
                    label="Approved"
                  />
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-select
                    ref="status"
                    v-model="searchParam.status"
                    :items="carrierStatusAll"
                    data-cy="status"
                    item-text="name"
                    item-value="id"
                    label="Status"
                  />
                </v-col>
                <v-col class="xs" cols="12" md="3" sm="4">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllCarrier()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container id="tblCarrier" class="pt-0 data-container" fluid>
        <v-data-table
          ref="table"
          :headers="headers"
          :items="carrierList"
          :items-per-page="pageSize"
          :loading="loadingIcon"
          calculate-widths
          class="elevation-2 data-table"
          fixed-header
          hide-default-footer
        >
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{
                name: 'Carrier Detail',
                params: { id: item.id },
              }"
              class="name-link"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:footer>
            <v-page
              ref="table-footer"
              :page-size-menu="[10, 20, 50, 100]"
              :total-row="totalRecord"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import CarrierSalesRepAutoComplete from '@/views/pages/components/CarrierSalesRepAutoComplete'

export default {
  name: "Carriers",
  components: {
      CarrierSalesRepAutoComplete
  },
  data() {
    return {
      searchParam: {
        name: null,
        city: null,
        state: null,
        mcNumber: null,
        dotNumber: null,
        scac: null,
        status: null,
        approved: null,
        carrierManager: null,
      },
      carrierStatusAll: [
        {
          id: 0,
          name: "None",
        },
        {
          id: 1,
          name: "Active",
        },
        {
          id: 2,
          name: "Inactive",
        },
      ],
      carrierApproved: [
        {
          id: 1,
          name: "Yes",
        },
        {
          id: 2,
          name: "No",
        },
      ],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "MC Number", value: "mcNumber", align: "start" },
        { text: "DOT Number", value: "dotNumber", align: "start" },
        { text: "Manager", value: "carrierManager", align: "start" },
        {
          text: "Base Location",
          value: "baseLocation",
          align: "start",
        },
        { text: "Shipments", value: "shipmentsCount", align: "center" },
        { text: "Status", value: "blacklist", align: "center" },
        {
          text: "Insurance",
          value: "insuranceActive",
          align: "center",
        },
        { text: "Approved", value: "approved", align: "center" },
      ],
      carrierList: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("carrier", ["carrierCompanys"]),
    ...mapGetters("generic", ["loadingIcon"]),
  },
  watch: {
    carrierCompanys() {
      this.totalRecord = this.carrierCompanys.count
      this.carrierList = this.carrierCompanys.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.carrierList = []
    this.getCarriers()
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split("/")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    getCarriers() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
    },
    resetSearch() {
      this.searchParam.name = null
      this.searchParam.city = null
      this.searchParam.state = null
      this.searchParam.mcNumber = null
      this.searchParam.dotNumber = null
      this.searchParam.scac = null
      this.searchParam.status = null
      this.searchParam.approved = null
      this.searchParam.carrierManager = null
      this.carrierList = []
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY_LIST", this.queryParams)
    },
    createQueryString() {
      this.queryParams = {}
      this.queryParams.limit = 20
      this.queryParams.offset = 0
      if (this.searchParam.name) {
        this.queryParams.name__icontains = this.searchParam.name
      }
      if (this.searchParam.city) {
        this.queryParams.city__icontains = this.searchParam.city
      }
      if (this.searchParam.state) {
        this.queryParams.state__iexact = this.searchParam.state
      }
      if (this.searchParam.mcNumber) {
        this.queryParams.mc_number__iexact = this.searchParam.mcNumber
      }
      if (this.searchParam.dotNumber) {
        this.queryParams.dot_number__iexact = this.searchParam.dotNumber
      }
      if (this.searchParam.scac) {
        this.queryParams.scac_number__iexact = this.searchParam.scac
      }
      if (this.searchParam.status && this.searchParam.status !== 0) {
        this.queryParams.blacklist = this.searchParam.status
      }
      if (this.searchParam.approved) {
        this.queryParams.setup_pending = this.searchParam.approved === 2
      }
      if (this.searchParam.carrierManager) {
        this.queryParams.carrier_manager = this.searchParam.carrierManager.id
      }
    },
    searchAllCarrier() {
      this.carrierList = []
      this.createQueryString()
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY_LIST", this.queryParams)
    },
    readDataFromAPI(value) {
      this.page = value.pageNumber
      this.pageSize = value.pageSize
      let pageNumber = this.pageSize * this.page - this.pageSize
      if (pageNumber < 0) {
        pageNumber = 0
      }
      this.queryParams.offset = pageNumber
      this.queryParams.limit = this.pageSize
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY_LIST", this.queryParams)
    },
    selectedCarrierManager(value) {
      if (value.csr) {
        this.searchParam.carrierManager = value.csr
        if (value.flag === 1) {
          this.searchAllCarrier()
        }
      }
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
